<template>
    <div class="col-12">
      <div class="row">
          <div class="col-lg-3 col-md-3 pb-3" v-for="(_, index) in Array(this.total).fill(null)" :key="index">
            <div class="skeleton-card d-flex align-items-center">
              <div class="col-12">
                <div class="row">
                  <div class="col-12">
                    <div class="row align-items-center">
                      <div class="col-6"> 
                        <div class="skeleton skeleton-image"></div>
                      </div>
                      <div class="col-6">
                        <div class="skeleton skeleton-text"></div>
                        <div class="skeleton skeleton-text"></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mt-4">
                    <div class="skeleton skeleton-text" style="width: 50%;"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'skeleton-card-report-analytics',
    props: {
      total: {
        type: Number,
        default: 25
      }
    }
}
</script>

<style>
.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}
.skeleton-card {
  border: 1px solid var(--card-border-color);
  border-radius: 8px;
  background-color: var(--background-color-secondary);
  box-shadow: 0 0 0 1px rgba(0,0,0,.05);
  height: 150px;
}

.skeleton-text {
  width: 100%;
  height: 0.7rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}

.skeleton-image {
  width: 50px;
  height: 50px;
  border-radius: 9999px;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 50%);
  }
  100% {
    background-color: hsl(200, 20%, 80%);
  }
}
</style>